// extracted by mini-css-extract-plugin
export var button = "vW_y3";
export var cell = "vW_cH3";
export var currentPrice = "vW_cP3";
export var headerWrapper = "vW_ct3";
export var isComingSoon = "vW_cK3";
export var mobile = "vW_K3";
export var previousPrice = "vW_cN3";
export var price = "vW_cM3";
export var selected = "vW_L3";
export var subTitle = "vW_cF3";
export var tag = "vW_x3";
export var title = "vW_cL3";